#projects-container{
    padding: 2rem;
    margin: 0 auto 2rem;
    text-align: center;
}

#menuProjet {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

#menuProjet button {
    background: none;
    border: none;
    padding: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    color: whitesmoke;
    transition: background-color 0.3s, color 0.3s;
    position: relative;
    margin-bottom: 3rem;
}

#menuProjet button::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: transparent;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

#menuProjet button:hover::before {
    visibility: visible;
    transform: scaleX(1);
    background-color: whitesmoke;
}

#menuProjet button.active {
    color: #42F3AC;
}

#menuProjet button.active::before {
    visibility: visible;
    transform: scaleX(1);
    background-color: #42F3AC;
}

.contenu-projet {
    margin-top: 2rem;
    color: whitesmoke;
}

@media (max-width: 1000px) {
    #projects-container{
        padding: 0rem;
        text-align: center;
        margin: auto;
        overflow-x: hidden;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Patua+One&display=swap');

body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; /* Empêche le défilement de la page */
    background: rgb(0,0,0);
    background: linear-gradient(138deg, rgba(0,0,0,1) 0%, rgba(66,15,168,1) 64%, rgba(0,0,0,1) 100%);
    background-attachment: fixed;
    background-size: cover; /* S'assure que l'arrière-plan couvre tout l'écran */
}

#presentation-container {
    opacity: 1;
    width: 100vw;
    height: 100vh;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center; /* Centrer verticalement */
    text-align: center;
    flex-direction: column; /* Afficher les éléments en colonne */
    overflow-y: scroll;
    margin: 0; /* Supprime les marges par défaut */
    padding: 0; /* Supprime les paddings par défaut */
    position: fixed; /* Fixe l'élément sur l'écran */
    top: 0; /* Aligne l'élément en haut de l'écran */
    left: 0; /* Aligne l'élément à gauche de l'écran */
}

.inactif{
    animation: fadeDown 1s ease-out forwards;
    animation-delay: 0.1s;
}

h1 {
    font-size: 3rem; /* Taille de la police */
    text-transform: uppercase; /* Transformation en majuscules */
    margin: 0; /* Réinitialisation de la marge */
    font-family: "Patua One", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 3px 3px 0 rgb(66, 15, 168);
}

h2 {
    font-size: 1.5rem; /* Taille de la police */
    margin: 0; /* Réinitialisation de la marge */
    font-family: "Arial", sans-serif;
    font-weight: 300;
    color: #ccc; /* Couleur de texte gris clair */
}

.scroll-down-arrow {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: bounce 2s infinite;
}

.scroll-down-arrow img {
    width: 10vw;
    max-width: 50px; /* Limiter la taille maximale de l'image */
    height: auto;
    transition: transform 0.3s;
}

.scroll-down-arrow:active img {
    transform: translateY(5px);
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
}


#content {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    /*background-color: rgba(0, 0, 0, 0.8);*/
    color: #fff;
    opacity: 0;
    z-index: 1000;
    padding: 2rem;
    box-sizing: border-box;
    font-size: 1.2rem;
}

#content.active {
    transform: translateY(20px);
    animation: fadeInUp 1s ease-out forwards;
    animation-delay: 0.1s;
}

@keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
      height: 50vh;
    }
  }

  @keyframes fadeDown {
    to {
      opacity: 0;
    }
  }
  
@media (max-width: 1000px) {
    #content {
        padding: 0.1rem;
    }
}
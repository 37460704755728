nav {
    position: fixed; /* Fixer la navbar en haut de l'écran */
    top: 1%; /* Ajustement pour la position en haut */
    right: 3%; /* Ajustement pour la position à droite */
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5); /* Couleur de fond semi-transparente */
    backdrop-filter: blur(10px); /* Effet de flou de fond */
    color: white;
    z-index: 1000; /* Assurez-vous que la navbar est au-dessus d'autres éléments */
    border: 5px solid rgba(0, 0, 0, 0.8); /* Bordure semi-transparente */
    border-radius: 12px;
    transition: box-shadow 0.3s ease-out, transform 0.3s;
  }
  
  nav div button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: rgba(68, 68, 68, 0.8); /* Couleur de fond semi-transparente */
    color: white;
    border: none;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  nav div button:hover {
    background-color: rgba(85, 85, 85, 0.8); /* Couleur de fond au survol */
    transform: scale(1.1); /* Augmenter légèrement la taille au survol */
  }
  
  nav:hover {
    box-shadow: 5px 5px rgba(48, 47, 47, 0.753);
    transform: scale(1.05); /* Ajustement pour une transition plus subtile */
  }
  
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Patua+One&display=swap');

#cinema-container {
    margin: 0 auto;
    padding: 2rem;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: whitesmoke;
}

.cinema-element {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid #421fa8;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 3rem;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
}

.cinema-element:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

#cinema-container h1 {
    font-size: 2.5rem;
    margin: 0 0 1rem 0;
    font-family: 'Patua One', serif;
    text-shadow: 3px 3px 0 rgb(66, 15, 168);
}

#cinema-container h2 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    font-family: 'Alegreya', serif;
}

#cinema-container p {
    font-size: 1.2rem;
    margin: 1rem 0;
    font-family: 'Alegreya', serif;
    text-align: center;
}

.cinema-video {
    width: 100%;
    border-radius: 10px;
    margin-top: 1rem;
}

iframe {
    width: 60rem;
    height: 33.75rem;
    border-radius: 10px;
    margin-top: 1rem;
}

.infos{
    font-style: oblique;
}

@media (max-width: 1200px) {
    #cinema-container {
        margin: auto;
        padding: 0.1rem;
    }

    .cinema-video {
        margin-top: 2rem;
    }

    .cinema-element {
        margin-bottom: 1rem;
    }

    iframe {
        width: 80vw; /* 90% de la largeur de l'écran */
        height: calc(80vw * 0.5625); /* Garde le ratio 16:9 */
    }
}
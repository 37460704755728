/* ContactIcon.css */
.contact-icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    font-size: 2rem;
    color: #ffffff; /* Couleur du texte pour s'harmoniser avec le thème */
    background-color: #19131d;
    padding: 10px;
    border: 3px solid #B9EDD7;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, background-color 0.3s;
    z-index: 1000; /* Ensure it stays on top */
}

.contact-icon:hover {
    transform: scale(1.1);
    background-color: #616060;
}

.contact-menu {
    position: absolute;
    bottom: 60px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.contact-menu a {
    color: #000;
    text-decoration: none;
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.contact-menu a svg {
    margin-right: 10px;
}

.contact-menu a:hover {
    color: #0077b5; /* Change to the respective brand color */
}

@media (max-width: 1000px) {
    .contact-icon {
        font-size: 1.5rem;
        padding: 8px;
        bottom: 10px;
        left: 10px;
    }

    .contact-menu {
        bottom: 50px;
        padding: 8px;
    }

    .contact-menu a {
        font-size: 0.9rem;
    }
}

#about-container {
    background-color: rgba(0, 0, 0, 0.356);
    border: 3px solid whitesmoke;
    padding: 2rem;
    transition: box-shadow 0.3s, transform 0.3s;
    box-sizing: border-box;
    margin: 0 auto 4rem;
    width: 90%;
    border-radius: 10px 10px 10px;
    position: relative;
    overflow: hidden;
}

#about-container:hover{
    transform: scale(1.01);
    box-shadow: 1px 1px 20px black;
}

#about-container p{
    margin-top: 3rem;
    text-align: justify;
    text-justify: newspaper;
}

#about-container .photo {
    max-width: 40%;
    max-height: 40%;
    border-radius: 50%;
    margin-top: 3rem;
    margin-bottom: 4rem;
    margin-right: 2rem;
    transition: transform 0.3s;
    float: left;

    /* EMPECHER SELECTION DU TEXTE */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
    -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
    user-select: none; /* Propriété standard */
}

#about-container .photo:hover{
    transform: scale(1.05);
}

@media (max-width: 1000px) {
    #about-container {
        border: 2px solid whitesmoke;
        padding: 2rem;
        box-sizing: border-box;
        margin: auto;
        margin-bottom: 3rem;
        width: 100%;
    }

    #about-container .photo {
        max-width: 80%;
        max-height: 80%;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 1rem;
        float: none;
    }

    #about-container p{
        text-align: center;
    }
}